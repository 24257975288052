body {
    background-color: #f5f5f5;
    font-family: 'Roboto', sans-serif;
    min-height: '100vh';
    overflow-x: 'hidden';
    width: "100%"
}



button {
  margin-top: .5px;
  background-color: #091A2C;
  padding: 6px;
  padding-bottom: 6px;
  margin: 5px;
  border: 2px solid#091a2d;
  border-radius: 5px;
  color: #fff;;
  font-size: 16px;
}

footer p {
  color: black !important;
}

input {
  border: none;
  width: 52%;
  border-radius: 5px;
  font-size: 16px;
  line-height: 1.5;
  padding: 5px;
  padding-bottom: 6px;
}

input:focus {
  outline-width: 0;
}

#about, #contact, #wheretobuy{
  min-height:70vh;
  display: -ms-flexbox;
  display: -webkit-flex;
       /* Center vertically */
       align-items: center;
       /*Center horizontaly */
       min-height:70vh;
     min-height:400px;
}
.ie {
  display:none;  
}
.hide-ie {
  display:block;
}
.ie-two {
  display:none;
}
.print-button {
  display: block;
  border:3px solid black;
    padding: 6px;
    margin-top:10px;
    background-color: #00be00;
    font-size: 23px;
    width:45%;
    border:none;
    cursor:pointer;
    font-family: 'Roboto', sans-serif;
    border-radius: 15px;
  }
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .ie {
  display:block;
  color:#a12735;
  font-size:22px;
  text-align:center;
  }
  .print-button {
    display:none;
    }
    .hide-ie {
      display:none;
    }
    .ie-two {
      display:block;
      font-size: 19px;
      padding: 6px;
      background-color: #00be00;
      border-radius: 15px;
    }
}

.tho-input{
  border:none;
}

.tho-button {
  border:3px solid black;
    padding: 6px;
    margin-top:10px;
    background-color: #00be00;;
    font-size: 23px;
    width:45%;
    border:none;
    cursor:pointer;
    font-family: 'Roboto', sans-serif;
    border-radius: 15px;
  }
  .tho-button:hover {
    transition: all 0.5s ease-in-out 0s;
    background-color: #029c02;
    box-shadow: 0 0 10px 0 rgba(17, 17, 17, 0.4);
  }
  .thoinv-button {
    padding: 6px;
    margin-top:10px;
    background-color: #e4e4e4;
    color:black;
    font-size: 23px;
    width:45%;
    border:none;
    cursor:pointer;
    border-radius: 40px;
  }
  .thoinv-button:hover {
    transition: all 0.5s ease-in-out 0s;
    background-color: #8a8888;
    box-shadow: 0 0 10px 0 rgba(17, 17, 17, 0.4);
  }
  .tpaper{
  padding:10px; 
  width:50%;
  }
  #the-logo {
    height: 65px;
  }
  .certificate{
    width: 650px;
    padding: 15px;
  }
  .cer-maintitle{
    font-size:35px;
    text-align: center;
    margin:10px 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
  }
  .cer-title{
    text-align: right;
  font-size: 13px;
  padding-right: 2px;
  margin: 8px;
  font-family: 'Roboto', sans-serif;

  }
  .cer-data{
    font-size: 13px;
    text-align: left;
    margin: 8px;
    font-family: 'Roboto', sans-serif;
  }
  .tho-smimg{
    height:60px;
  }
  
  .end{
    text-align: end;
  }
  .start{
    text-align: start;
  }
  @media only screen and (max-width: 600px) {
    .end{
      text-align: center;
    }
    .start{
      text-align: center;
    }
    .tho-button {
      width:70%;
    }
    .tpaper{
      margin:0;
      padding:0;
      width:100%;
      }
      .cer-maintitle{
        font-size:17px;       
      }
      .tho-input{
        padding:12px;
        width:100%;
      }
      .certificate{
        width:300px;
      }
      #the-logo{
        height: 45px;
      }
      .tho-button {       
        font-size: 12px;
        width:70%;        
      }
      .thoinv-button {       
        font-size: 12px;
        width:70%;        
      }
      .cer-title{
      font-size: 12px;
      margin: 5px;
      }
      .cer-data{
        font-size: 12px;
        margin: 5px;
      }
     
  }

  h1{
    padding:10px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }